import posthog from 'posthog-js'

import type { SerializeFrom } from '@remix-run/server-runtime'

import { configFeatures } from '~/config/features'
import type { getClientEnv } from '~/utils'

export { posthog }

type Env = ReturnType<typeof getClientEnv>

export function PostHogInit({ env }: { env: SerializeFrom<Env> }) {
  // Turn off in dev
  if (env.APP_ENV === 'development') return null

  const posthogApiKey = String(env.POSTHOG_API_KEY)
  const posthogApiHost =
    String(env.POSTHOG_API_HOST) || 'https://app.posthog.com'

  if (configFeatures.posthog) {
    posthog.init(posthogApiKey, { api_host: posthogApiHost })
    if (configFeatures.console) console.info('PostHog is initialized')
  }
  return null
}
