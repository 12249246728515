/**
 * Config Features
 */
export const configFeatures = {
  console: false,
  // toast: true,
  posthog: true,
  posthogBrowser: true,
  posthogNode: true,
  sentryBrowser: true,
  sentryNode: true,
  // crisp: true,
} as const
